<template>
	<div class="detailsInspect">
		<div class="detailsInspect-item">
			<p class="title">基本信息</p>
			<div class="detailsInspect-item-iter">
				<img src="@/assets/imgs/loginImg.png" alt="" />
				<div class="iter clearfix">
					<div v-for="(item, index) in testData" :key="index">
						<p class="float-l">
							{{ item.name }}
						</p>
						<p class="float-l" v-if="item.value">{{ item.value }}</p>
					</div>
				</div>
			</div>
		</div>
		<div class="detailsInspect-item">
			<p class="title">检查结果</p>
			<Table
				:loading="loading"
				:table-data="tableData"
				:table-head="tableHead"
				:is-show-selection="false"
				:showOperation="false"
				:isShowPage="false"
				:tableHeight="tableHeight"
			>
				<!-- 插槽操作栏固定属性operation -->
				<template #images="{ scope }">
					<div class="imgsClass">
						<img v-if="scope.row.images" :src="scope.row.images" alt="" />
						<span v-else>-</span>
					</div>
				</template>
			</Table>
		</div>
	</div>
</template>

<script>
export default {
	name: 'detailsInspect',
	data() {
		return {
			testData: [
				{
					name: '姓名',
					value: '',
				},
				{
					name: '性别',
					value: '',
				},
				{
					name: '出生日期',
					value: '',
				},
				{
					name: '身份证号',
					value: '',
				},
				{
					name: '工作地址',
					value: '',
				},
				{
					name: '常住类型',
					value: '',
				},
				{
					name: '本人电话',
					value: '',
				},
				{
					name: '联系人姓名',
					value: '',
				},
				{
					name: '联系人电话',
					value: '',
				},
				{
					name: '民 族',
					value: '',
				},
			],
			loading: false,
			tableHead: [
				{
					label: '所属疾病',
					prop: 'disease',
					formatter: (row) => {
						return row.disease || '-';
					},
				},
				{
					label: '检查内容',
					prop: 'inspection',
					formatter: (row) => {
						return row.inspection || '-';
					},
				},
				{
					label: '上传图片',
					prop: 'images',
				},
			],
			tableData: [],
		};
	},
	computed: {
		tableHeight() {
			return window.innerHeight - 550;
		},
	},
	components: {
		Table: () => import('@/components/Table/table'),
	},
	mounted() {
		this.getDetails();
	},
	methods: {
		getDetails() {
			if (!this.$route.query.link) return;
			this.$http
				.get(this.$route.query.link)
				.then((res) => {
					if (res.data && res.data.success) {
						let collection = res.data.collection[0] || {};
						if (!collection) return;
						let arr = collection.diseaseDictionaryItemList.map((item) => {
							return {
								disease: item.diseaseDictionaryItem?.title || '-',
								inspection: item.inspectionDictionaryItem?.title || '-',
								images: item.attachmentDtoMap?.PHOTO[0]?.link || '-',
							};
						});
						this.tableData = JSON.parse(JSON.stringify(arr)) || [];
						if (collection.personDto) {
							this.testData[0].value = collection.personDto?.name || '-';
							this.testData[1].value = collection.personDto?.genderDictionaryItem?.title || '-';
							this.testData[2].value = collection.personDto?.birthDate || '-';
							this.testData[3].value = collection.personDto?.identificationCode || '-';
							this.testData[4].value = collection.personDto?.workAddress || '-';
							this.testData[5].value = collection.personDto?.hukou ? '常驻' : '非常驻';
							this.testData[6].value = collection.personDto?.phoneNumber || '-';
							this.testData[7].value = collection.personDto?.name || '-';
							this.testData[8].value = collection.personDto?.phoneNumber || '-';
							this.testData[9].value = collection.personDto?.ethnicityDictionaryItem?.title || '-';
						}
					}
				})
				.catch((e) => {});
		},
	},
};
</script>

<style lang="scss" scoped>
.detailsInspect {
	background-color: #fff;
	border-radius: 2px;
	margin: 16px;
	padding: 40px 0;
	&-item {
		padding: 0 40px;
		margin-bottom: 40px;
		&:last-child {
			margin-bottom: 0;
		}
		.title {
			font-size: 16px;
			color: #333333;
			padding-left: 8px;
			border-left: 3px solid #1db9b1;
			line-height: 16px;
			margin-bottom: 16px;
		}
		&-iter {
			position: relative;
			border-right: 1px solid rgba(214, 217, 224, 0.8);
			border-bottom: 1px solid rgba(214, 217, 224, 0.8);
			img {
				position: absolute;
				right: 0;
				top: 0;
				width: 148px;
				height: 192px;
			}
			.iter {
				width: calc(100% - 148px);
				p {
					color: #333;
					padding-left: 16px;
					line-height: 48px;
					height: 48px;
					border: 1px solid rgba(214, 217, 224, 0.8);
					width: calc(50% - 160px);
					border-bottom: none;
					border-right: none;
					&:nth-child(2n-1) {
						width: 160px;
						background-color: #f4f6fd;
						border-right: none;
					}
				}
			}
		}
	}
	/deep/ .el-table {
		tr th {
			background-color: #f4f6fd !important;
		}
		td.el-table__cell,
		.el-table th.el-table__cell.is-leaf,
		.el-table--border,
		.el-table--group {
			border-color: rgba(214, 217, 224, 0.8) !important;
		}
	}
	.imgsClass {
		img {
			width: 60px;
			height: 60px;
		}
	}
}
</style>